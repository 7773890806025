import { Button as FLButton, ButtonProps as FLButtonProps, Spinner } from '@fluentui/react-components';
import React from 'react';

type ButtonProps = FLButtonProps & {
    loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, disabled, icon, ...props }) => {
    return (
        <FLButton {...props} disabled={disabled ?? loading} icon={loading ? <Spinner size="tiny" /> : icon}>
            {children}
        </FLButton>
    );
};

export default Button;
