import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Tooltip } from '@fluentui/react-components';
import { BugRegular, QuestionCircle24Regular, Rocket24Regular } from '@fluentui/react-icons';
import { Constants } from '../../Constants';

export const HelpMenu = () => {

    const handleGetStarted = () => {
        window.open(Constants.settings.getStartedUrl, '_blank', 'noopener,noreferrer');
    };

    const handleReportBug = () => {
        window.open(Constants.settings.reportBugUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Tooltip content="Help" relationship="label">
                    <Button icon={<QuestionCircle24Regular color='white' />} aria-label="Help" appearance="transparent" />
                </Tooltip>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem icon={<Rocket24Regular />} onClick={handleGetStarted}>
                        Get Started
                    </MenuItem>
                    <MenuItem icon={<BugRegular />} onClick={handleReportBug}>
                            Report a Bug
                    </MenuItem>

                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
