import { useMsal } from "@azure/msal-react";
import { AuthHelper } from "../libs/auth/AuthHelper";

export function useGetAccessToken(){
    const { instance, inProgress } = useMsal();

    const getAccessToken = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        return accessToken
    }

    return { getAccessToken };
}
