import { useMsal } from '@azure/msal-react';
import { Body1, Button, Title3 } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useSharedClasses } from '../../styles';
import { getErrorDetails } from '../utils/TextUtils';
import './Login.css';

// const loginLogo =
//     'https://th.bing.com/th/id/R.3d6a2ad56bc3403c5cfcc3efe09b741b?rik=gnNKMMZSvZ3uMA&riu=http%3a%2f%2fpurepng.com%2fpublic%2fuploads%2flarge%2fpurepng.com-microsoft-logo-iconlogobrand-logoiconslogos-251519939091wmudn.png&ehk=1%2fl4i5MeDLTCpvZhUZlCefvhSzsGR16HIPqagpDxYDg%3d&risl=&pid=ImgRaw&r=0';

interface LoginProps {
    backgroundImage: string;
    loginHeaderTitle: string;
    loginHeaderTitleColor: string;
    loginBody: string;
    loginBodyColor: string;
    loginContainerColor: string;
    loginBTNName: string;
    loginBTNColor: string;
    loginBTNTextColor: string;
    loginBTNLogo: string;
}

export const Login: React.FC<LoginProps> = ({
    backgroundImage,
    loginHeaderTitle,
    loginHeaderTitleColor,
    loginBody,
    loginBodyColor,
    loginContainerColor,
    loginBTNName,
    loginBTNTextColor,
    loginBTNColor,
    loginBTNLogo,
}: LoginProps) => {
    const { instance } = useMsal();
    const classes = useSharedClasses();
    const [styles, setStyles] = useState<React.CSSProperties>({});

    useEffect(() => {
        setStyles({
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginTop: 'auto',
            color: loginBodyColor,
            backgroundColor: loginContainerColor,
        });
    }, [backgroundImage, loginBodyColor, loginContainerColor]);

    const handleSignIn = () => {
        instance.loginRedirect().catch((e: unknown) => {
            alert(`Error signing in: ${getErrorDetails(e)}`);
        });
    };

    return (
        <>
            <div className={`${classes.informativeView} login-container`} style={{ ...styles, height: '100vh' }}>
                <div
                    className="container"
                    style={{
                        backgroundColor: loginContainerColor,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    <div className="logo-container">
                        <h1 style={{ color: loginHeaderTitleColor }}>{loginHeaderTitle}</h1>
                    </div>
                    <Title3 style={{ color: loginBodyColor }}>{loginBody}</Title3> <br />
                    <Body1 style={{ color: loginBodyColor }}>
                        {/* {"Don't have an account? Create one for free at"}{' '}
                        <a
                            href="https://account.microsoft.com/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', fontWeight: 500, color: loginBodyColor }}
                        >
                            Create Account
                        </a> */}
                    </Body1>{' '}
                    {/* <br /> */}
                    {/* <center>
                        <Button
                            style={{
                                padding: 0,
                                marginTop: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            appearance="transparent"
                            onClick={handleSignIn}
                            data-testid="signinButton"
                        >
                            <Image src={signInLogo} />
                        </Button>
                    </center> */}
                    <center>
                        <Button
                            onClick={handleSignIn}
                            appearance="transparent"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                border: 'none',
                                backgroundColor: loginBTNColor,
                                color: loginBTNTextColor,
                                cursor: 'pointer',
                                marginTop: '50px',
                            }}
                        >
                            <img
                                src={loginBTNLogo}
                                alt="Logo"
                                style={{ marginRight: '10px', width: '25px', height: '25px' }}
                            />
                            <span style={{ fontSize: '17px' }}>{loginBTNName}</span>
                        </Button>
                    </center>
                </div>
            </div>
        </>
    );
};
