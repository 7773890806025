import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC, useState } from 'react';
import { useUserAvatar } from '../../hooks';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

interface chatRoomData {
    initialMSG: string;
}


const ChatView: FC<chatRoomData> = ({initialMSG}) => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const avatarUrl = useUserAvatar(activeUserInfo?.id);


    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={classes.container}>
            <ChatList isCollapsed={isCollapsed} onToggleCollapse={toggleCollapse} />
            {selectedId !== '' && (
                <ChatWindow initialMSG={initialMSG} userPhotoUrl={avatarUrl} isCollapsed={isCollapsed} />
            )}
        </div>
    );
};


export default ChatView;
