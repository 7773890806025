import { useState } from "react";

export function useLoading(defaultValue = false) {
    const [loading, setLoading] = useState(defaultValue);

    const handleLoading = (value?: boolean) => {
        if (value !== undefined) {
            setLoading(value)
            return;
        }

        setLoading(prevState => !prevState)
    }

    return {loading, handleLoading}
}
