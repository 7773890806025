import { IPersona } from '../models/Persona';
import { BaseService } from './BaseService';

interface ImageUploadResponse {
    imageUrl: string;
}
export class PersonaService extends BaseService {
    public UpsertPersonaAsync = async (
        persona: IPersona,
        accessToken: string,
    ): Promise<{ message: string; persona: IPersona }> => {
        return await this.getResponseAsync(
            {
                commandPath: '/persona/create-persona',
                method: 'POST',
                body: persona,
            },
            accessToken,
        );
    };

    public GetPersonasAsync = async (accessToken: string): Promise<IPersona[]> => {
        return await this.getResponseAsync(
            {
                commandPath: '/persona/get-personas',
                method: 'GET',
            },
            accessToken,
        );
    };

    public GetPersonaById = async (accessToken: string, personaId: string): Promise<IPersona> => {
        return await this.getResponseAsync(
            {
                commandPath: `/persona/get-persona/${personaId}`,
                method: 'GET',
            },
            accessToken,
        );
    };

    public DeletePersonaAsync = async (persona: IPersona, accessToken: string): Promise<void> => {
        await this.getResponseAsync(
            {
                commandPath: '/persona/delete-persona',
                method: 'DELETE',
                body: persona,
            },
            accessToken,
        );
    };
    public DeletePersonaDocumentAsync = async (documentId: string, accessToken: string) => {
        return await this.getResponseAsync(
            {
                commandPath: `/document/${documentId}`,
                method: 'DELETE',
            },
            accessToken,
        );
    };
    async uploadPersonaImage(file: File, personaId: string, accessToken: string): Promise<ImageUploadResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('personaId', personaId);

        const request = {
            commandPath: '/persona/upload-image',
            method: 'POST',
            body: formData,
        };

        return this.getResponseAsync<ImageUploadResponse>(request, accessToken);
    }
    async getPersonaImage(personaId: string, accessToken?: string): Promise<Blob> {
        const commandPath = `/persona/get-image/${personaId}`;
        const headers = new Headers();
        accessToken && headers.append('Authorization', `Bearer ${accessToken}`);

        const response = await fetch(commandPath, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.blob();
    }

    public DeleteImageAsync = async (personaId: string, accessToken?: string) => {
        return await this.getResponseAsync(
            {
                commandPath: `/persona/images/delete-image/${personaId}`, // Use route parameter
                method: 'DELETE',
            },
            accessToken,
        );
    };
    public SharePersonaWithUserAsync = async (
        personaId: string,
        userId: string,
        accessToken: string,
    ): Promise<void> => {
        // console.log('personaId:', personaId);
        // console.log('userId:', userId);

        await this.getResponseAsync(
            {
                commandPath: `share/${userId}/${personaId}`, // Adjusted path
                method: 'POST',
                // body: JSON.stringify({ userId: userId }), // Adjusted body
            },
            accessToken,
        );
    };

    public UnsharePersonaWithUserAsync = async (
        personaId: string,
        userId: string,
        accessToken: string,
    ): Promise<void> => {
        await this.getResponseAsync(
            {
                commandPath: `unshare/${userId}/${personaId}`, // Adjusted path
                method: 'POST',
                // body: JSON.stringify({ userId }), // Adjusted body
            },
            accessToken,
        );
    };
}
