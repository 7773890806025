import { BrandingSettings } from '../models/BrandingSettings';
import { BaseService } from './BaseService';
import { v4 as uuidv4 } from 'uuid';



export interface SuperAdmin {
    email: string;
    name: string;
    role: string;
}

export class BrandingSettingsService extends BaseService {
    public getBrandingSettingsAsync = async (): Promise<BrandingSettings | null> => {
        try {
            const result = await this.getResponseAsync<BrandingSettings>({
                commandPath: `branding-settings`,
                method: 'GET',
            });
            return result;
        } catch (error) {
            // Handle error (e.g., branding settings not found)
            return null;
        }
    };

    public updateBrandingSettingsAsync = async (
        updatedSettings: BrandingSettings,
        accessToken: string,
        settingsId: string,
    ): Promise<void> => {
        try {
            const uuid: string = uuidv4();
            updatedSettings.Id = settingsId;
            updatedSettings.PartitionKey="default";
            updatedSettings.RowKey =uuid;
            // const actualUserId = userId;
            await this.getResponseAsync({
                // commandPath: `branding-settings/${actualUserId}/${settingsId}`,
                commandPath: `branding-settings/${settingsId}`,
                method: 'PUT',
                body: updatedSettings,
            },
                accessToken
                    );
        } catch (error) {
            // Handle error (e.g., failed to update branding settings)
        }

    };

    public getSuperAdminsAsync = async (): Promise<SuperAdmin[] | null> => {
        try {
            const result = await this.getResponseAsync<SuperAdmin[]>({
                commandPath: 'api/users', // Assuming 'users' is the endpoint for superadmins
                method: 'GET',
            });
            return result;
        } catch (error) {
            // Handle error (e.g., failed to fetch superadmins)
            console.error('Failed to fetch superadmins:', error);
            return null;
        }
    };
    
}
