import { useCallback, useEffect, useState } from 'react';
import { useGraph } from '../libs/hooks';

export const useUserAvatar = (activeUserId: string | undefined) => {
    const [userAvatarUrl, setUserAvatarUrl] = useState<string | null>(null);
    const { getUserPhoto } = useGraph();

    const loadAvatar = useCallback(async () => {
        if (!activeUserId) return;

        try {
            const photoUrl = await getUserPhoto(activeUserId);
            setUserAvatarUrl(photoUrl);
        } catch (error) {
            console.error('Failed to load user avatar:', error);
            setUserAvatarUrl(null);
        }
    }, [activeUserId, getUserPhoto]);

    useEffect(() => {
        loadAvatar().catch((error) => {
            console.error('Error in useEffect:', error);
        });
    }, [loadAvatar]);

    return userAvatarUrl;
};
